import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from "react";
import "./Header.scss";
import { AppContext } from "../../../contexts/AppContext";
import translate from "src/i18n/translate";
export default function Header(_a) {
    var displayName = _a.displayName;
    var queryString = window.location.search;
    var _b = useContext(AppContext), institution = _b.institution, sysUser = _b.sysUser, breadcrumb = _b.breadcrumb;
    var headerBody = function () {
        if (institution) {
            var REDIRECT_URI = runConfig.REDIRECT_URI;
            var showHomeBreadcrum = breadcrumb === "home" ||
                (sysUser &&
                    sysUser.roles.includes("contributor") &&
                    sysUser.roles.length === 1);
            var urlSuffix = queryString.includes("locale") ? "/".concat(queryString) : "";
            var academicOfficeUrl = "/insights/academic_office".concat(urlSuffix);
            var homeUrl = "/insights/home".concat(urlSuffix);
            var breadCrumbsUrl = showHomeBreadcrum ? homeUrl : academicOfficeUrl;
            return (_jsx("div", { className: "page-banner -mainheader -withbreadcrumbs only-breadcrumbs no-shadow", children: _jsxs("div", { className: "", id: "page-header", children: [_jsxs("section", { className: "bread-crumbs -crumb", children: [_jsx("a", { href: institution.navigatedFrom === "planning"
                                        ? REDIRECT_URI
                                        : breadCrumbsUrl, id: "link-0-page-banner", className: displayName ? "link" : "link home-link", children: _jsx("span", { className: "breadcrumb-title", children: institution.navigatedFrom === "planning" || showHomeBreadcrum
                                            ? translate("app.mainnav.home")
                                            : translate("app.mainnav.academicsAndOffices") }) }), _jsx("span", { className: "org-name", children: displayName })] }), _jsx("section", { className: "identity-details institution-name-home", children: _jsx("div", { className: "page-header -titleonly", children: _jsx("h2", { className: "title -new-title", children: displayName }) }) })] }) }));
        }
    };
    return _jsx(_Fragment, { children: headerBody() });
}
function UserIcon() {
    return (_jsx("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.99967 0.333008C3.31967 0.333008 0.333008 3.31967 0.333008 6.99967C0.333008 10.6797 3.31967 13.6663 6.99967 13.6663C10.6797 13.6663 13.6663 10.6797 13.6663 6.99967C13.6663 3.31967 10.6797 0.333008 6.99967 0.333008ZM6.99967 2.33301C8.10634 2.33301 8.99967 3.22634 8.99967 4.33301C8.99967 5.43967 8.10634 6.33301 6.99967 6.33301C5.89301 6.33301 4.99967 5.43967 4.99967 4.33301C4.99967 3.22634 5.89301 2.33301 6.99967 2.33301ZM6.99967 11.7997C5.33301 11.7997 3.85967 10.9463 2.99967 9.65301C3.01967 8.32634 5.66634 7.59967 6.99967 7.59967C8.32634 7.59967 10.9797 8.32634 10.9997 9.65301C10.1397 10.9463 8.66634 11.7997 6.99967 11.7997Z", fill: "#575195" }) }));
}
