var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import "../../../app/App.scss";
import { IntlProvider, useIntl } from "react-intl";
import ENGLISH from "../../../i18n/languages/en-US.json";
import { useParams } from "react-router-dom";
import { pig } from "../../../static/js/pig.js";
import Header from "./Header";
import Loader from "../../common/elements/Loader";
import translate from "src/i18n/translate";
import { sendRequest } from "src/common/http";
import { WmProgressIndicator, WmProgressMonitor, WmProgressSlice } from "@watermarkinsights/ripple-react";
import { formatDateFullYear } from "src/features/common/helper";
import { arrow } from "../../ces/ResponseRate";
import { RenderHierarchyTable } from "src/features/ces/OpenDrillDownViewResponseRate";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
import ApiError from "src/features/common/elements/ApiError";
var defaultCourseEvaluation = {
    endDate: "",
    projectStatus: "",
    startDate: "",
    title: "",
    projectId: null,
    surveyId: null,
    daysRemaining: 0
};
var defaultPreviousProject = {
    diffInResponseRate: 0,
    isDiffPositive: false,
    responseRate: 0,
    title: ""
};
var defaultResponseRateState = {
    average: 0,
    averageDifference: 0,
    awaiting: "",
    isAverageDifferencePositive: false,
    responded: "",
    previousProject: defaultPreviousProject,
    selectedProject: defaultCourseEvaluation
};
var defaultAction = {
    type: "",
    locale: "en-US",
    responseRate: defaultResponseRateState,
    drilldownHierarchy: null,
    responseRateError: false,
    nodeResponseRateError: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setLocale":
            return setLocale(state, action.locale);
        case "setResponseRate":
            return __assign(__assign({}, state), { responseRate: action.responseRate });
        case "setResponseRateError":
            return __assign(__assign({}, state), { responseRateError: action.responseRateError });
        case "setHierarchy":
            return __assign(__assign({}, state), { drilldownHierarchy: action.drilldownHierarchy });
        case "setNodeResponseRateError":
            return __assign(__assign({}, state), { nodeResponseRateError: true });
        default:
            return state;
    }
};
var setLocale = function (state, locale) {
    switch (locale) {
        case "pig": {
            return __assign(__assign({}, state), { locale: "pig", messages: pig(ENGLISH) });
        }
        default: {
            return __assign(__assign({}, state), { locale: "en-US", messages: ENGLISH });
        }
    }
};
var initialState = {
    locale: "en-US",
    messages: null,
    responseRate: defaultResponseRateState,
    drilldownHierarchy: null,
    responseRateError: false,
    nodeResponseRateError: false
};
export default function HtmlView(_a) {
    var auth = _a.auth;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var _c = useParams(), surveyId = _c.survey_id, projectId = _c.project_id;
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale") || initialState.locale;
        dispatch(__assign(__assign({}, defaultAction), { type: "setLocale", locale: locale }));
    }, []);
    useEffect(function () {
        if (surveyId && projectId) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setResponseRate", responseRate: result }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultAction), { type: "setResponseRateError", responseRateError: true }));
            };
            auth &&
                sendRequest({
                    uri: "/ces/response_rate/survey/".concat(surveyId, "/project/").concat(projectId),
                    camelize: true
                }, applyResult, onError);
        }
    }, [auth, surveyId, projectId]);
    useEffect(function () {
        if (surveyId && projectId) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setHierarchy", drilldownHierarchy: result }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultAction), { type: "setNodeResponseRateError" }));
            };
            auth &&
                sendRequest({
                    uri: "/ces/node_response_rate/project/".concat(projectId),
                    camelize: true
                }, applyResult, onError);
        }
    }, [auth, surveyId, projectId]);
    var showLoader = state.responseRate.selectedProject.projectId === null &&
        state.drilldownHierarchy === null &&
        !state.responseRateError &&
        !state.nodeResponseRateError;
    return (_jsx(_Fragment, { children: auth ? (_jsx("div", { className: "pagewrapper ces-html-container", children: _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { className: "nav-main", children: _jsx("div", { className: "contentwrapper", children: _jsx("main", { id: "maincontent", className: "main-container", children: state.messages && (_jsx(IntlProvider, { locale: state.locale, messages: state.messages, children: _jsxs("div", { id: "cesHtml", className: "ces-html-container", children: [showLoader && _jsx(Loader, {}), _jsx(Header, { showDownloadBtn: !state.responseRate || !state.nodeResponseRateError }), _jsxs("section", { className: "-mainheader -withbreadcrumbs only-breadcrumbs body-padding", id: "page-header", "data-export-id": "ces-export", children: [!state.responseRateError && (_jsx(ProjectDetails, { project: state.responseRate.selectedProject })), _jsxs("div", { id: "response-rate-html", className: "response-rate-container", children: [state.responseRateError ? (_jsx(ApiError, { containerClasses: "api-error" })) : (_jsxs(_Fragment, { children: [_jsx(ResponseRateChart, { responseRate: state.responseRate }), _jsx(ResponseRateTrends, { responseRate: state.responseRate })] })), _jsx(ResponseRateTable, { drilldownHierarchy: state.drilldownHierarchy, apiError: state.nodeResponseRateError })] })] })] }) })) }) }) }) }) })) : (_jsx(UnauthorizedComponent, {})) }));
}
var ProjectDetails = function (_a) {
    var project = _a.project;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex-row -justified plan-status-container plan-status-container-html", children: [_jsx("div", { className: "plan-title", children: project.title }), _jsx(CourseEvaluationStatus, { status: project.projectStatus })] }), _jsxs("div", { className: "ces-duration", children: [_jsx("div", { className: "ces-details", children: translate("app.ces.responseRate.details") }), _jsxs("div", { className: "ces-dates", children: [_jsx("span", { className: "mdi mdi-calendar-clock" }), "".concat(formatDateFullYear(project.startDate), " - ").concat(formatDateFullYear(project.endDate))] }), project.daysRemaining > 0 && (_jsxs("div", { className: "ces-days", children: [_jsx("span", { className: "mdi mdi-timer-sand" }), translate("app.ces.responseRate.daysRemaining", {
                                daysRemaining: project.daysRemaining
                            })] }))] })] }));
};
var CourseEvaluationStatus = function (_a) {
    var status = _a.status;
    switch (status) {
        case "InProgress":
            return (_jsx("div", { className: "in-progress-status", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.inProgress") }) }));
        case "Ended":
            return (_jsx("div", { className: "ended-status -completed", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.ended") }) }));
        default:
            return _jsx(_Fragment, {});
    }
};
var ResponseRateChart = function (_a) {
    var responseRate = _a.responseRate;
    var project = responseRate.selectedProject;
    var respondedText = useIntl().formatMessage({
        id: "app.ces.responseRate.responded"
    });
    var popoverRespondedText = useIntl().formatMessage({
        id: "app.ces.responseRate.respondedPopover"
    });
    var popoverAwaitingText = useIntl().formatMessage({
        id: "app.ces.responseRate.respondedAwaitingPopover"
    });
    var awaitingText = useIntl().formatMessage({
        id: "app.ces.responseRate.awaiting"
    });
    var respondedAwaiting = useIntl().formatMessage({
        id: "app.ces.responseRate.respondedAwaiting"
    });
    var responseRateLabel = useIntl().formatMessage({
        id: "app.ces.responseRate.responseRate"
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "response-rate-label", children: translate("app.ces.responseRate.overallResponseRate") }), _jsx("div", { className: "response-rate-chart", children: _jsx(WmProgressMonitor, { id: "ces-response-rate", "group-legend": respondedAwaiting, breakpoint: "10px", children: _jsxs(WmProgressIndicator, { label: responseRateLabel, children: [_jsx(WmProgressSlice, { amount: responseRate.responded, "popover-text": popoverRespondedText, legend: respondedText, "popover-title": "".concat(responseRate.responded, "%"), title: project.title }), _jsx(WmProgressSlice, { amount: responseRate.awaiting, "popover-text": popoverAwaitingText, legend: awaitingText, "popover-title": "".concat(responseRate.awaiting, "%"), title: project.title })] }) }) })] }));
};
var ResponseRateTrends = function (_a) {
    var responseRate = _a.responseRate;
    return (_jsx("div", { className: "response-rate-divider", children: _jsxs("div", { className: "response-rate-trends", children: [_jsx("div", { className: "response-rate-trends-heading", children: translate("app.ces.responseRate.responseRateTrends") }), responseRate.average !== null ? (_jsxs("div", { className: "response-rate-trends-container", children: [_jsxs("div", { className: "response-rate-all-courses", children: [_jsxs("div", { className: "flex-row", children: [_jsx("span", { className: "-icons mdi ".concat(arrow(responseRate.isAverageDifferencePositive)) }), _jsxs("div", { className: "comparison-rate-all-courses", children: [responseRate.averageDifference, "%"] })] }), _jsx("div", { className: "response-rate-trends-desc", children: translate("app.ces.responseRate.responseRateTrendsDescriptionAllCourses", {
                                        comparisonRate: responseRate.average
                                    }) })] }), _jsx("div", { className: "response-rate-previous-course", children: responseRate.previousProject && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex-row", children: [_jsx("span", { className: "-icons mdi ".concat(arrow(responseRate.previousProject.isDiffPositive)) }), _jsxs("div", { className: "comparison-rate-all-courses", children: [responseRate.previousProject.diffInResponseRate, "%"] })] }), _jsx("div", { className: "response-rate-trends-desc", children: translate("app.ces.responseRate.responseRateTrendsDescriptionPreviousCourse", {
                                            previousProjectTitle: responseRate.previousProject.title,
                                            comparisonRate: responseRate.previousProject.responseRate
                                        }) })] })) })] })) : (_jsx(EmptyResponseRateTrends, {}))] }) }));
};
var EmptyResponseRateTrends = function () {
    return (_jsxs("div", { className: "flex-row no-response-rate", children: [_jsx("span", { className: "-icons mdi mdi-timer-sand" }), _jsx("div", { className: "no-response-rate-text", children: translate("app.ces.responseRate.NoResponseRateText") })] }));
};
var ResponseRateTable = function (_a) {
    var drilldownHierarchy = _a.drilldownHierarchy, apiError = _a.apiError;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "ces-duration ces-duration-drilldown", children: translate("app.ces.responseRate.byOrganzationProgram") }), drilldownHierarchy && !apiError ? (_jsx(_Fragment, { children: _jsx("div", { className: "table-border", children: _jsx(RenderHierarchyTable, { completeHierarchy: drilldownHierarchy }) }) })) : (_jsx(ApiError, { containerClasses: "api-error" }))] }));
};
