export var STEPS_FOR_EDGE_CASE = 13;
export var IN_PROGRESS_STATUS = "IN_PROGRESS";
export var SUBMITTED_STATUS = "SUBMITTED";
export var ON_HOLD_STATUS = "ON_HOLD";
export var SKIPPED_STATUS = "SKIPPED";
export var NOT_STARTED_STATUS = "NOT_STARTED";
export var SENT_BACK_STATUS = "SENT_BACK";
export var LEFT = "left";
export var RIGHT = "right";
export var FIXED = "FIXED";
export var ENTER = "Enter";
export var TAB = "tab";
export var ARROW_LEFT = "ArrowLeft";
export var ARROW_RIGHT = "ArrowRight";
export var ORGANIZATIONS_API_ERROR = "organizationsApiError";
export var PROGRAMS_API_ERROR = "programsApiError";
export var COURSES_API_ERROR = "coursesApiError";
export var STATS_API_ERROR = "statsApiError";
