var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmPagination, WmTabItem, WmTabList, WmTabPanel } from "@watermarkinsights/ripple-react";
import { CompletedTasks } from "./CompletedTasks";
import { useEffect, useContext, useReducer } from "react";
import { sendRequest } from "src/common/http";
import { useIntl } from "react-intl";
import { AppContext } from "src/contexts/AppContext";
import translate from "src/i18n/translate";
import { formatDateWithMonthAbbr, scrollToTop } from "src/features/common/helper";
import { Link, useNavigate } from "react-router-dom";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import { UserGroupIcon, SettingsIcon, FolderIcon } from "src/features/common/Icons";
import { pageSize, isTaskInCurrentPage } from "src/features/common/tabComponent/InsightsTabComponent";
var defaultAction = {
    type: "",
    tabId: "",
    tab: "",
    currentPage: 1,
    tasks: []
};
var reducer = function (state, action) {
    var currentTaskCount = state.taskCount;
    switch (action.type) {
        case "setSelectedTab":
            return __assign(__assign({}, state), { selectedTab: action.tabId });
        case "setPage":
            return __assign(__assign({}, state), { currentPage: action.currentPage });
        case "setTasks":
            var taskCount = action.tasks
                ? currentTaskCount + action.tasks.length
                : currentTaskCount;
            return __assign(__assign({}, state), { tasks: action.tasks, taskCount: taskCount, loadedTasks: true });
        default:
            return state;
    }
};
var initialState = {
    selectedTab: "to-do",
    loadedTasks: false,
    taskCount: 0,
    tasks: null,
    currentPage: 1
};
export function MyTasks() {
    var _a;
    var _b = useContext(AppContext), institution = _b.institution, sysUser = _b.sysUser;
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        if (institution && institution.salesforceId) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setTasks", tasks: result }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultAction), { type: "setTasks", tasks: [] }));
            };
            sendRequest({
                uri: "/home/tasks",
                camelize: true
            }, applyResult, onError);
        }
    }, [institution]);
    var onWmTabSelected = function (event) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setSelectedTab", tabId: event.detail.tabId }));
    };
    var navigateToExternalTask = function (url) {
        window.location.href = url;
    };
    var handleKeyPressFs = function (event, url) {
        if (event.key === " " || event.key === "Enter") {
            navigateToExternalTask(url);
        }
    };
    var toDoLabel = useIntl().formatMessage({
        id: "app.home.toDoLabel"
    }, { count: state.loadedTasks && state.taskCount });
    var reportingYearTaskHeading = useIntl().formatMessage({
        id: "app.home.createReportingYear"
    });
    var reportingYearTaskDesc = useIntl().formatMessage({
        id: "app.home.createReportingYearDesc"
    });
    var completedLabel = useIntl().formatMessage({
        id: "app.home.completedLabel"
    });
    var tabOption = [
        {
            name: toDoLabel,
            id: "to-do"
        },
        {
            name: completedLabel,
            id: "completed"
        }
    ];
    function showEmptyState() {
        var _a;
        return state.loadedTasks && ((_a = state.tasks) === null || _a === void 0 ? void 0 : _a.length) === 0;
    }
    function showTasks() {
        var _a;
        return state.loadedTasks && state.tasks && ((_a = state.tasks) === null || _a === void 0 ? void 0 : _a.length) > 0;
    }
    var setPage = function (event) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setPage", currentPage: event.target.value }));
    };
    return (_jsxs("div", { className: (sysUser === null || sysUser === void 0 ? void 0 : sysUser.roles.includes("administrator"))
            ? "my-tasks-card"
            : "my-tasks-card my-tasks-margin", children: [_jsxs("div", { className: "card-header", children: [_jsx("div", { className: "my-tasks-heading", children: translate("app.home.myTasks") }), _jsx("div", { className: "base-font", children: translate("app.home.myTasksDesc") })] }), _jsx("div", { className: "tabbar", children: _jsx(WmTabList, { id: "my-tasks-tabs", selectedTab: state.selectedTab, onWmTabSelected: onWmTabSelected, children: tabOption.map(function (tab, index) { return (_jsx(WmTabItem, { tabId: tab.id, children: tab.name }, tab.id + (index + 1))); }) }) }), _jsx(WmTabPanel, { className: "tab-panel", id: "tab-one", tabId: "to-do", children: _jsxs("div", { className: "tab-container", children: [_jsx("div", { className: "tab-content-title", children: state.loadedTasks &&
                                translate("app.home.tasksToDo", { count: state.taskCount }) }), showEmptyState() && _jsx(ToDoEmptyState, {}), !state.loadedTasks && (_jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" })), showTasks() && (_jsxs(_Fragment, { children: [(_a = state.tasks) === null || _a === void 0 ? void 0 : _a.filter(function (_, index) {
                                    return isTaskInCurrentPage(index, state.currentPage);
                                }).map(function (task, index) {
                                    return (_jsx(RenderTask, { task: task, navigateToExternalTask: navigateToExternalTask, handleKeyPressFs: handleKeyPressFs }, "".concat(task.title, "-").concat(index)));
                                }), state.taskCount > pageSize && (_jsx(WmPagination, { currentPage: state.currentPage, itemsPerPage: pageSize, totalItems: state.taskCount, onWmPaginationPageClicked: setPage }))] }))] }) }), _jsx(WmTabPanel, { className: "tab-panel", id: "tab-two", tabId: "completed", children: _jsx("div", { className: "tab-container", children: _jsx(CompletedTasks, {}) }) })] }));
}
function ToDoEmptyState() {
    return (_jsx("div", { className: "tab-content-desc", children: translate("app.home.toDoEmptyState") }));
}
var queryString = window.location.search;
var formatCSTaskDetails = function (title, state) {
    switch (state.toLowerCase()) {
        case "draft":
            return [
                useIntl().formatMessage({ id: "app.home.csSubmitTaskTitle" }, { title: title }),
                useIntl().formatMessage({ id: "app.home.csSubmitTaskDesc" })
            ];
        default:
            return [
                useIntl().formatMessage({ id: "app.home.csResubmitTaskTitle" }, { title: title }),
                useIntl().formatMessage({ id: "app.home.csResubmitTaskDesc" })
            ];
    }
};
var formatInsightTaskDetails = function (state) {
    switch (state.toLowerCase()) {
        case "share_access_to_institution":
            return [
                useIntl().formatMessage({ id: "app.home.insightsInstitution" }),
                useIntl().formatMessage({ id: "app.home.insightsInstitutionDesc" })
            ];
        case "share_access_to_program":
            return [
                useIntl().formatMessage({ id: "app.home.insightsProgram" }),
                useIntl().formatMessage({ id: "app.home.insightsProgramDesc" })
            ];
        case "reporting_year_task":
            return [
                useIntl().formatMessage({ id: "app.home.createReportingYear" }),
                useIntl().formatMessage({ id: "app.home.createReportingYearDesc" })
            ];
        default:
            return ["", ""];
    }
};
var formatTaskDetails = function (product, title, state) {
    switch (product.toLowerCase()) {
        case "curriculum strategy":
            return formatCSTaskDetails(title, state);
        case "faculty success":
            return [
                useIntl().formatMessage({ id: "app.home.fsTaskTitle" }, { title: title }),
                useIntl().formatMessage({ id: "app.home.fsTaskDesc" }, { title: title })
            ];
        case "insights":
            return formatInsightTaskDetails(state);
        default:
            return ["", ""];
    }
};
function RenderTask(_a) {
    var task = _a.task, navigateToExternalTask = _a.navigateToExternalTask, handleKeyPressFs = _a.handleKeyPressFs;
    return task.product == "Insights" ? (_jsx(InsightsTask, { task: task })) : (_jsx(ExternalTask, { task: task, navigateToExternalTask: navigateToExternalTask, handleKeyPressFs: handleKeyPressFs }));
}
function ExternalTask(_a) {
    var task = _a.task, navigateToExternalTask = _a.navigateToExternalTask, handleKeyPressFs = _a.handleKeyPressFs;
    var _b = formatTaskDetails(task.product, task.title, task.state), taskTitle = _b[0], taskDescription = _b[1];
    var dueDate = useIntl().formatMessage({ id: "app.home.taskDueDate" }, { date: formatDateWithMonthAbbr(task.dueDate) });
    var lastModifyDate = useIntl().formatMessage({ id: "app.home.taskLastUpdate" }, { date: formatDateWithMonthAbbr(task.lastModifyDate) });
    return (_jsxs("div", { className: "task-container", onClick: function () { return navigateToExternalTask(task.url); }, onKeyDown: function (event) { return handleKeyPressFs(event, task.url); }, children: [_jsxs("div", { className: "flex-row create-reporting-year-link", children: [_jsx(FolderIcon, { color: "#575195" }), _jsx("a", { className: "reporting-year-link", href: task.url, children: _jsx("span", { className: "read-more", children: taskTitle }) })] }), _jsxs("div", { className: "task-content flex-row", children: [_jsxs("div", { className: "task-description", children: [taskDescription, _jsxs("span", { className: "task-type", children: [" ", task.product, "."] })] }), task.dueDate && _jsx("div", { className: "task-due-date", children: dueDate }), task.lastModifyDate && (_jsx("div", { className: "task-due-date", children: lastModifyDate }))] })] }));
}
var getIcon = function (state) {
    if (state == "reporting_year_task") {
        return _jsx(SettingsIcon, {});
    }
    else {
        return _jsx(UserGroupIcon, {});
    }
};
function InsightsTask(_a) {
    var task = _a.task;
    var _b = formatTaskDetails(task.product, task.title, task.state), taskTitle = _b[0], taskDescription = _b[1];
    var navigate = useNavigate();
    function navigateToUrl() {
        scrollToTop(".home-page-header");
        queryString.includes("locale")
            ? navigate("".concat(task.url, "/").concat(queryString))
            : navigate(task.url);
    }
    function handleKeyDownLink(event) {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            navigateToUrl();
        }
    }
    return (_jsxs("div", { className: "task-container", onClick: navigateToUrl, onKeyDown: handleKeyDownLink, children: [_jsxs("div", { className: "flex-row create-reporting-year-link", children: [getIcon(task.state), _jsx(Link, { to: queryString.includes("locale")
                            ? "".concat(task.url, "/").concat(queryString)
                            : task.url, className: "reporting-year-link", onKeyDown: handleKeyDownLink, children: _jsx("span", { className: "read-more", children: taskTitle }) })] }), _jsx("div", { className: "task-content flex-row", children: _jsx("div", { className: "task-description", children: taskDescription }) })] }));
}
