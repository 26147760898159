import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../common/elements/Header.scss";
import InsightsFooter from "./common/InsightsFooter";
import InsightsHeader from "./common/InsightsHeader";
import NavigatorSideNav from "./NavigatorSideNav";
export var InsightsHeaderSidebar = function (_a) {
    var user = _a.user, children = _a.children, hideNavigatorSideNav = _a.hideNavigatorSideNav;
    return (_jsxs("div", { className: hideNavigatorSideNav
            ? "pagewrapper show-toggle-nav-container"
            : "pagewrapper newHome", children: [_jsx(InsightsHeader, { hideNavigatorSideNav: hideNavigatorSideNav }), _jsxs("div", { className: "nav-main", children: [user && user.uuid !== "" && user.roles.includes("administrator") && (_jsx(NavigatorSideNav, { hideNavigatorSideNav: hideNavigatorSideNav, collapseSideNav: true })), _jsx("div", { className: "contentwrapper background", children: _jsxs("div", { id: "maincontent", children: [children, _jsx(InsightsFooter, {})] }) })] })] }));
};
