export var defacultFsDeeplinks = {
    activities: null,
    "activities:add": null
};
export var defaultPlan = {
    assessmentTypeId: 0,
    status: 0,
    title: "",
    updatedAt: "",
    uuid: ""
};
export var defaultPlanResultSet = {
    plans: [],
    selectedPlan: defaultPlan
};
export var defaultTerm = {
    academicYear: "",
    startDate: "",
    endDate: "",
    institutionUuid: "",
    name: "",
    termId: "",
    uuid: ""
};
export var defaultReportingYear = {
    endDate: "",
    name: "",
    startDate: "",
    uuid: "",
    isCurrent: false
};
export var defaultInstitution = {
    authCode: "",
    authType: "",
    isPssIntegrated: false,
    name: "",
    navigatedFrom: "",
    orgLeads: "",
    organization: null,
    platformUuid: "",
    pssUuid: "",
    reportingYear: defaultReportingYear,
    rootOrgUuid: "",
    salesforceId: "",
    showCourseEvaluation: false,
    showCurriculumTab: false,
    showLightcastPreview: false,
    showProgramReview: false,
    isSllSysAdminIntegrated: false,
    rootOrgPlatformUuid: "",
    fsLogoUrl: null
};
export var defaultOrganization = {
    uuid: "",
    name: "",
    type: "",
    code: "",
    platformUuid: ""
};
export var defaultMembership = {
    userRole: "",
    salesforceId: ""
};
export var defaultInsightsUser = {
    email: "",
    firstName: "",
    uuid: "",
    lastName: "",
    roles: [],
    products: [],
    logoutUrl: "",
    gainsightData: null
};
