import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "../../../i18n/translate";
import React from "react";
import { formatDateFullYear } from "../../common/helper";
import ApiError from "../../common/elements/ApiError";
export default function ParticipatingTable(_a) {
    var orgHierarchy = _a.orgHierarchy, leads = _a.leads, heading = _a.heading, planStatus = _a.planStatus, apiError = _a.apiError;
    var renderHierarchy = function (node) {
        return (_jsxs(React.Fragment, { children: [_jsx(Node, { node: node, leads: leads }), node.children &&
                    node.children.map(function (child) {
                        return renderHierarchy(child);
                    })] }, "".concat(node.uuid, "-hierarchy")));
    };
    var TableBody = function (props) {
        return _jsx("tbody", { className: "databody", children: props.children });
    };
    var Table = function (props) {
        return _jsx("table", { className: "data-table -shadow", children: props.children });
    };
    var TableHeader = function (props) {
        return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                    return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
                }) }) }));
    };
    var Node = function (props) {
        var node = props.node, leads = props.leads;
        return (_jsx(_Fragment, { children: node.showStats ? (_jsx(CompleteRow, { node: node, lead: leads[node["uuid"]] })) : (_jsx(NoStatsRow, { node: node })) }));
    };
    var NoStatsRow = function (props) {
        var node = props.node;
        return (_jsxs("tr", { children: [_jsx("td", { className: "datacell level-".concat(node.level), children: node.name }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" })] }, "".concat(node.uuid, "-datarow")));
    };
    var CompleteRow = function (props) {
        var node = props.node, lead = props.lead;
        var leadName = function () {
            if (typeof lead != "undefined") {
                return lead.leads.map(function (l, index) {
                    return (_jsx("div", { className: "leadcell", children: l }, "lead-".concat(index, "-").concat(node.uuid)));
                });
            }
        };
        return (_jsxs("tr", { children: [_jsx("td", { className: "datacell level-".concat(node.level, " organization-cell"), children: node.name }), _jsx(StatusCell, { status: node.status }), _jsx(StatsCell, { node: node }), _jsx("td", { className: "datacell", children: leadName() })] }, "".concat(node.uuid, "-datarow")));
    };
    var StatsCell = function (props) {
        var node = props.node;
        var stats = function () {
            return node.updatedAt
                ? formatDateFullYear(node.updatedAt)
                : translate("app.na");
        };
        return _jsx("td", { className: "datacell stats-cell", children: stats() });
    };
    var StatusCell = function (props) {
        var content = function () {
            switch (props.status) {
                case "not_mapped":
                    return (_jsx("div", { className: "not-started-status", children: translate("app.drilldown.notMapped") }));
                case "not_started":
                    return (_jsx("div", { className: "not-started-status leadcell", children: translate("app.drilldown.notStarted") }));
                case "mapped":
                    return (_jsx("div", { className: "success-status -mapped leadcell", children: _jsx("div", { className: "title-text leadcell", children: translate("app.drilldown.mapped") }) }));
                case "submitted":
                    return (_jsx("div", { className: "success-status status-export-table leadcell", children: _jsx("span", { className: "title-text leadcell", children: translate("app.drilldown.submitted") }) }));
                case "in_progress":
                    var inProgress = planStatus == 1
                        ? translate("app.drilldown.inComplete")
                        : translate("app.drilldown.inProgress");
                    return (_jsx("div", { className: "in-progress-status-export leadcell", children: _jsx("span", { className: "leadcell", children: inProgress }) }));
            }
        };
        return _jsx("td", { className: "datacell status-cell", children: content() });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "table-heading", children: heading }), apiError ? (_jsx(ApiError, { containerClasses: "api-error" })) : (_jsx("div", { className: "org-table-export", children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: [
                                translate("app.drilldown.organization"),
                                translate("app.drilldown.status"),
                                translate("app.drilldown.lastUpdated"),
                                translate("app.drilldown.lead")
                            ] }), _jsx(TableBody, { children: renderHierarchy(orgHierarchy) })] }) }))] }));
}
