var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { WmPagination } from "@watermarkinsights/ripple-react";
import { useEffect, useContext, useReducer } from "react";
import { sendRequest } from "src/common/http";
import { useIntl } from "react-intl";
import { AppContext } from "src/contexts/AppContext";
import translate from "src/i18n/translate";
import { formatDateWithMonthAbbr } from "src/features/common/helper";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import { FolderIcon, CheckCircleFilled } from "src/features/common/Icons";
import { pageSize, isTaskInCurrentPage } from "src/features/common/tabComponent/InsightsTabComponent";
var defaultAction = {
    type: "",
    completedTasks: [],
    currentPage: 1
};
var reducer = function (state, action) {
    var currentCompletedTaskCount = state.completedTaskCount;
    switch (action.type) {
        case "setCompletedTasks":
            var completedTaskCount = action.completedTasks
                ? currentCompletedTaskCount + action.completedTasks.length
                : currentCompletedTaskCount;
            return __assign(__assign({}, state), { completedTasks: action.completedTasks, completedTaskCount: completedTaskCount, loadedCompletedTasks: true });
        case "setPage":
            return __assign(__assign({}, state), { currentPage: action.currentPage });
        default:
            return state;
    }
};
var initialState = {
    completedTaskCount: 0,
    completedTasks: null,
    loadedCompletedTasks: false,
    currentPage: 1
};
export function CompletedTasks() {
    var _a;
    var _b = useContext(AppContext), institution = _b.institution, sysUser = _b.sysUser;
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        if (institution && institution.salesforceId) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setCompletedTasks", completedTasks: result }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultAction), { type: "setCompletedTasks", completedTasks: [] }));
            };
            sendRequest({
                uri: "/home/completed-tasks",
                camelize: true
            }, applyResult, onError);
        }
    }, [institution]);
    function showEmptyState() {
        var _a;
        return state.completedTasks && ((_a = state.completedTasks) === null || _a === void 0 ? void 0 : _a.length) === 0;
    }
    function showCompletedTasks() {
        return (state.loadedCompletedTasks &&
            state.completedTasks &&
            state.completedTasks.length > 0);
    }
    var setPage = function (event) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setPage", currentPage: event.target.value }));
    };
    return (_jsxs("div", { className: "tab-container", children: [showEmptyState() && _jsx(CompletedEmptyState, {}), !state.loadedCompletedTasks && (_jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" })), showCompletedTasks() && (_jsxs(_Fragment, { children: [(_a = state.completedTasks) === null || _a === void 0 ? void 0 : _a.filter(function (_, index) {
                        return isTaskInCurrentPage(index, state.currentPage);
                    }).map(function (task, index) { return (_jsx(RenderCompletedTask, { task: task }, "".concat(task.title, "-").concat(index))); }), state.completedTaskCount > pageSize && (_jsx(WmPagination, { currentPage: state.currentPage, itemsPerPage: pageSize, totalItems: state.completedTaskCount, onWmPaginationPageClicked: setPage }))] }))] }));
}
function CompletedEmptyState() {
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "tab-content-title", children: translate("app.home.tasksCompleted", { count: 0 }) }), _jsx("div", { className: "tab-content-desc", children: translate("app.home.completedEmptyState") })] }));
}
var formatTaskDetails = function (product, title, state) {
    switch (product.toLowerCase()) {
        case "faculty success":
            return [
                useIntl().formatMessage({ id: "app.home.fsTaskTitle" }, { title: title }),
                useIntl().formatMessage({ id: "app.home.fsTaskDesc" }, { title: title })
            ];
        default:
            return ["", ""];
    }
};
function RenderCompletedTask(_a) {
    var task = _a.task;
    var _b = formatTaskDetails(task.product, task.title, task.state), taskTitle = _b[0], taskDescription = _b[1];
    var completedDate = useIntl().formatMessage({ id: "app.home.taskCompletedDate" }, { date: formatDateWithMonthAbbr(task.completedDate) });
    return (_jsxs("div", { className: "task-container completed-task", children: [_jsxs("div", { className: "flex-row create-reporting-year-link task-content", children: [_jsxs("div", { className: "flex-row create-reporting-year-link title-container", children: [_jsx("span", { children: _jsx(FolderIcon, { color: "#6B6B6B" }) }), _jsx("span", { className: "title", children: taskTitle })] }), _jsxs("div", { className: "flex-row completed-date", children: [_jsx(CheckCircleFilled, {}), task.completedDate && (_jsx("div", { className: "task-due-date read-more", children: completedDate }))] })] }), _jsx("div", { className: "task-content flex-row", children: _jsxs("div", { className: "task-description", children: [taskDescription, " ", task.product, "."] }) })] }));
}
