import { jsx as _jsx } from "react/jsx-runtime";
import { WmTabList, WmTabItem } from "@watermarkinsights/ripple-react";
export var pageSize = 4;
export var isTaskInCurrentPage = function (index, currentPage) {
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = currentPage * pageSize;
    return index >= startIndex && index < endIndex;
};
export default function InsightsTabComponent(props) {
    var st = props.tabOption.filter(function (tab) {
        return tab.id === props.selectedTab;
    });
    var selectedTab = st.length > 0 ? props.selectedTab : "institution-insights";
    return (_jsx(WmTabList, { id: "tab-with-controllers", selectedTab: selectedTab, onWmTabSelected: props.onWmTabSelected, children: props.tabOption.map(function (tab) { return (_jsx(WmTabItem, { id: tab.id, tabId: tab.id, children: tab.name }, tab.id)); }) }));
}
