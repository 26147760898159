var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer } from "react";
import { AppContext } from "src/contexts/AppContext";
import { sendRequest } from "src/common/http";
import translate from "src/i18n/translate";
import { WorkFlowTaskProgress } from "./workflowTask/WorkflowTaskProgress";
import ApiError from "src/features/common/elements/ApiError";
var defaultAction = {
    type: "",
    workflowTasks: null,
    apiError: false
};
var initialState = {
    workflowTasks: null,
    apiError: false
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "getWorkflowTasks":
            return __assign(__assign({}, state), { workflowTasks: action.workflowTasks });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: true });
        default:
            return state;
    }
};
export function ReviewsInProgress() {
    var _a = useContext(AppContext), sysUser = _a.sysUser, institution = _a.institution;
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "getWorkflowTasks", workflowTasks: result }));
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setApiError" }));
        };
        sendRequest({
            uri: "/faculty_success/rpt-workflow-tasks?sfid=".concat(institution === null || institution === void 0 ? void 0 : institution.salesforceId),
            camelize: true
        }, applyResult, onError);
    }, []);
    return ((state.workflowTasks || state.apiError) && (_jsxs("div", { className: (sysUser === null || sysUser === void 0 ? void 0 : sysUser.roles.includes("administrator"))
            ? "widget-card"
            : "widget-card my-tasks-margin", children: [_jsxs("div", { className: "card-header", children: [_jsx("div", { className: "my-tasks-heading", children: translate("app.home.reviewsInProgress") }), _jsx("div", { className: "base-font", children: translate("app.home.reviewsInProgress.desc") })] }), _jsxs("div", { className: "widget-body", children: [state.apiError && (_jsx("div", { className: "api-error-wrapper", children: _jsx(ApiError, { containerClasses: "api-error" }) })), state.workflowTasks && state.workflowTasks.length === 0 && (_jsx(ReviewInProgressEmptyState, {})), state.workflowTasks &&
                        state.workflowTasks.length > 0 &&
                        state.workflowTasks.map(function (workflowTask) {
                            var _a;
                            return (_jsx(WorkFlowTaskProgress, { workflowTask: workflowTask, totalWorkflows: ((_a = state.workflowTasks) === null || _a === void 0 ? void 0 : _a.length) || 0 }, workflowTask === null || workflowTask === void 0 ? void 0 : workflowTask.id));
                        })] })] })));
}
export function ReviewInProgressEmptyState() {
    return (_jsx("div", { className: "flex-row empty-state-reviews", children: _jsx("div", { className: "empty-state-msg", children: translate("app.home.reviewsInProgress.emptyState") }) }));
}
