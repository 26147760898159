import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ClockIcon, ExpandIcon, ExpandLess, OnHoldIcon, SentBackIcon, SkippedIcon, TickIcon } from "src/features/common/Icons";
import { stepClass } from "./WorkflowTaskStep";
import { WorkflowTaskStepTooltip } from "./WorkflowTaskStepTooltip";
import { ENTER, IN_PROGRESS_STATUS, NOT_STARTED_STATUS, ON_HOLD_STATUS, SENT_BACK_STATUS, SKIPPED_STATUS, SUBMITTED_STATUS, TAB } from "src/features/common/constants";
export function WorkflowTaskStepSmallScreen(_a) {
    var step = _a.step, showStepIndex = _a.showStepIndex, tooltipData = _a.tooltipData, openTooltip = _a.openTooltip;
    function handleKeyPress(event, stepIndex) {
        if (event.key === ENTER) {
            openTooltip(stepIndex);
        }
        else if (event.key === TAB && step.stepIndex === showStepIndex) {
            openTooltip(stepIndex);
        }
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { onClick: function () { return openTooltip(step.stepIndex); }, className: step.stepIndex === showStepIndex
                    ? "step-small-screen tabbing ".concat(stepClass(step.state), " expand-details")
                    : "step-small-screen hover tabbing ".concat(stepClass(step.state)), onKeyDown: function (event) { return handleKeyPress(event, step.stepIndex); }, tabIndex: 0, children: [_jsx("div", { className: "step-content", children: step.state === NOT_STARTED_STATUS ? (step.name) : (_jsxs("div", { className: "flex-row in-progress-content", children: [_jsxs("div", { className: "clock-icon", children: [step.state === IN_PROGRESS_STATUS && _jsx(ClockIcon, {}), step.state === SUBMITTED_STATUS && _jsx(TickIcon, {}), step.state === SENT_BACK_STATUS && (_jsx("div", { className: "sent-back", children: _jsx(SentBackIcon, {}) })), step.state === SKIPPED_STATUS && (_jsx("div", { className: "skipped", children: _jsx(SkippedIcon, {}) })), step.state === ON_HOLD_STATUS && (_jsx("div", { className: "on-hold", children: _jsx(OnHoldIcon, {}) }))] }), _jsx("div", { className: "step-name", children: step.name })] })) }), step.stepIndex !== showStepIndex ? (_jsx("div", { children: _jsx(ExpandIcon, {}) })) : (_jsx("div", { children: _jsx(ExpandLess, {}) }))] }), step.stepIndex === showStepIndex && tooltipData && (_jsx(WorkflowTaskStepTooltip, { tooltipData: tooltipData }))] }));
}
