var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer, useContext, Fragment } from "react";
import { WmChart, WmChartSlice, WmSelect, WmOption, WmButton } from "@watermarkinsights/ripple-react";
import "../common/Insights.scss";
import { openFlyout } from "../../static/js/commons";
import OpenDrillDownView from "../common/OpenDrillDownView";
import { sendRequest } from "../../common/http";
import { AppContext } from "../../contexts/AppContext";
import translate from "src/i18n/translate";
import { useIntl } from "react-intl";
import WidgetLoader from "../common/elements/WidgetLoader";
import ApiError from "../common/elements/ApiError";
import { defaultPlanResultSet, defaultPlan } from "../common/interfaces/Interface";
var ASSESSMENT_PLANNING_ORGANIZATIONS = "assessment_planning_organizations";
var ASSESSMENT_PLANNING_PROGRAMS = "assessment_planning_programs";
var REDIRECT_URI = runConfig.REDIRECT_URI;
var defaultChartData = {
    completedCount: 0,
    completedPrgmCount: 0,
    submittedCourseCount: 0,
    inProgressCount: 0,
    inProgressPrgmCount: 0,
    inProgressCourseCount: 0,
    notStartedCount: 0,
    notStartedCourseCount: 0,
    notStartedPrgmCount: 0,
    totalParticipatingOrgs: 0,
    totalParticipatingPrgms: 0,
    totalParticipatingCourses: 0,
    totalPrograms: 0,
    totalOrganizations: 0,
    totalCourses: 0
};
var defaultPlanParticipationAction = {
    type: "",
    chart: defaultChartData,
    loader: false,
    partialLoader: false,
    planResultSet: defaultPlanResultSet,
    plan: defaultPlan,
    drilldownData: {
        drilldownType: "",
        drilldownStatus: "",
        drilldownTotal: ""
    },
    apiError: false,
    hierarchy: {}
};
export default function PlanParticipation(_a) {
    var selectedReportingYear = _a.selectedReportingYear, onComponentLoad = _a.onComponentLoad, resetLoadedComponent = _a.resetLoadedComponent;
    var queryString = window.location.search;
    var param = new URLSearchParams(queryString);
    var selectedPlanUuid = param.get("plan_uuid");
    var initialSelectedPlan = {
        uuid: "",
        title: "",
        assessmentTypeId: 0,
        status: 0,
        updatedAt: ""
    };
    var initialState = {
        drilldownType: "",
        drilldownTotal: "",
        drilldownStatus: "any",
        selectedPlan: initialSelectedPlan,
        isDrilldownOpen: false,
        plans: [],
        chartData: {
            completedCount: 0,
            completedPrgmCount: 0,
            submittedCourseCount: 0,
            inProgressCount: 0,
            inProgressPrgmCount: 0,
            inProgressCourseCount: 0,
            notStartedCount: 0,
            notStartedCourseCount: 0,
            notStartedPrgmCount: 0,
            totalParticipatingOrgs: 0,
            totalParticipatingPrgms: 0,
            totalParticipatingCourses: 0,
            totalPrograms: 0,
            totalOrganizations: 0,
            totalCourses: 0
        },
        hierarchy: null,
        showTable: false,
        drilldownFilters: [],
        loader: true,
        partialLoader: true,
        plansApiError: false,
        statsApiError: false
    };
    var defaultDrilldownFilters = function (status) {
        var inProgress = status === 1
            ? translate("app.drilldown.inComplete")
            : translate("app.drilldown.inProgress");
        return [
            { value: "in_progress", name: inProgress },
            { value: "not_started", name: translate("app.drilldown.notStarted") },
            { value: "submitted", name: translate("app.drilldown.submitted") }
        ];
    };
    var getDrilldownFilters = function (drilldownType, status) {
        if (drilldownType === ASSESSMENT_PLANNING_ORGANIZATIONS) {
            return null;
        }
        else {
            return defaultDrilldownFilters(status);
        }
    };
    var getSelectedPlan = function (plans) {
        if (selectedPlanUuid === undefined) {
            return undefined;
        }
        else {
            var plan = plans.filter(function (plan) { return plan.uuid === selectedPlanUuid; });
            return plan[0];
        }
    };
    var reducer = function (state, action) {
        switch (action.type) {
            case "setChartData":
                return __assign(__assign({}, state), { chartData: action.chart, partialLoader: false });
            case "setLoader":
                return __assign(__assign({}, state), { loader: action.loader });
            case "setPlanLoader":
                return __assign(__assign({}, state), { partialLoader: action.partialLoader });
            case "setPlans":
                return __assign(__assign({}, state), { plans: action.planResultSet.plans, loader: false, selectedPlan: getSelectedPlan(action.planResultSet.plans) ||
                        action.planResultSet.selectedPlan ||
                        initialSelectedPlan });
            case "setSelectedPlan":
                return __assign(__assign({}, state), { selectedPlan: action.plan });
            case "openDrilldown":
                return __assign(__assign({}, state), { isDrilldownOpen: true });
            case "closeDrilldown":
                return __assign(__assign({}, state), { isDrilldownOpen: false, hierarchy: null });
            case "setDrilldownData":
                return __assign(__assign({}, state), { drilldownType: action.drilldownData.drilldownType, drilldownTotal: action.drilldownData.drilldownTotal, drilldownStatus: action.drilldownData.drilldownStatus, drilldownFilters: getDrilldownFilters(action.drilldownData.drilldownType, state.selectedPlan.status) });
            case "setHierarchy":
                return __assign(__assign({}, state), { hierarchy: action.hierarchy, showTable: true });
            case "setPlansApiError":
                return __assign(__assign({}, state), { plansApiError: action.apiError, loader: false, partialLoader: false });
            case "setStatsApiError":
                return __assign(__assign({}, state), { statsApiError: action.apiError, loader: false, partialLoader: false });
            default:
                return state;
        }
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var _c = useContext(AppContext), leads = _c.leads, institution = _c.institution;
    var openDrilldownView = function () {
        dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "openDrilldown" }));
    };
    var closeDrilldown = function () {
        dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "closeDrilldown" }));
    };
    var redirect = function (url) {
        window.location.replace("".concat(url));
    };
    var resetErrorStates = function () {
        dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setStatsApiError", apiError: false }));
        dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setPlansApiError", apiError: false }));
    };
    useEffect(function () {
        resetErrorStates();
        dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setLoader", loader: true }));
        if (selectedReportingYear && selectedReportingYear.uuid) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setPlans", planResultSet: {
                        plans: result.plans,
                        selectedPlan: result.selectedPlan
                    } }));
                if (result.plans.length === 0) {
                    onComponentLoad("planParticipationPlans");
                    onComponentLoad("planParticipationChartData");
                }
                else {
                    onComponentLoad("planParticipationPlans");
                }
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setPlansApiError", apiError: true }));
                onComponentLoad("planParticipationPlans");
                onComponentLoad("planParticipationChartData");
            };
            sendRequest({
                uri: "/plans?reporting_year_uuid=".concat(selectedReportingYear.uuid),
                camelize: true
            }, applyResult, onError);
        }
    }, [selectedReportingYear]);
    var _d = state.selectedPlan, planUuid = _d.uuid, selectedPlanTitle = _d.title;
    useEffect(function () {
        if (state.plans.length > 0) {
            resetLoadedComponent("planParticipationChartData");
        }
        var applyResult = function (chart) {
            dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setChartData", chart: chart }));
            if (state.plans.length > 0) {
                onComponentLoad("planParticipationChartData");
            }
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setStatsApiError", apiError: true }));
            if (state.plans.length !== 0) {
                onComponentLoad("planParticipationChartData");
            }
        };
        if (planUuid) {
            dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setPlanLoader", partialLoader: true }));
            sendRequest({ uri: "/plan/".concat(planUuid, "/stats"), camelize: true }, applyResult, onError);
        }
    }, [planUuid]);
    "";
    var getDrilldownHierarchy = function (hierarchyURI, focusAfterbtn) {
        openDrilldownView();
        setTimeout(function () {
            return openFlyout("drill-down-view", focusAfterbtn, "drill-down-close-btn");
        }, 100);
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setHierarchy", hierarchy: result }));
        };
        sendRequest({ uri: hierarchyURI, camelize: true }, applyResult);
    };
    var getDrilldownData = function (drillDownNameVal) {
        var _a = state.chartData, totalParticipatingOrgs = _a.totalParticipatingOrgs, totalOrganizations = _a.totalOrganizations, totalParticipatingPrgms = _a.totalParticipatingPrgms, totalPrograms = _a.totalPrograms;
        switch (drillDownNameVal) {
            case ASSESSMENT_PLANNING_ORGANIZATIONS:
                return {
                    drilldownTotal: translate("app.planParticipation.ofOrgParticipating", {
                        totalParticipatingOrgs: totalParticipatingOrgs,
                        totalOrganizations: totalOrganizations
                    }),
                    hierarchyURI: "/hierarchy/participating_organizations/".concat(state.selectedPlan.uuid)
                };
            case ASSESSMENT_PLANNING_PROGRAMS:
                return {
                    drilldownTotal: translate("app.planParticipation.ofPrgmParticipating", {
                        totalParticipatingPrgms: totalParticipatingPrgms,
                        totalPrograms: totalPrograms
                    }),
                    hierarchyURI: "/hierarchy/participating_programs/".concat(state.selectedPlan.uuid)
                };
            default:
                return {
                    drilldownTotal: "",
                    hierarchyURI: ""
                };
        }
    };
    var openDrillDown = function (drillDownTypeVal, drilldownStatus, focusAfterbtn) {
        var drilldownData = getDrilldownData(drillDownTypeVal);
        getDrilldownHierarchy(drilldownData.hierarchyURI, focusAfterbtn);
        dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setDrilldownData", drilldownData: {
                drilldownType: drillDownTypeVal,
                drilldownStatus: drilldownStatus,
                drilldownTotal: drilldownData.drilldownTotal
            } }));
    };
    var PlanSelect = function (_a) {
        var plans = _a.plans, selectedPlan = _a.selectedPlan;
        return (_jsx(WmSelect, { label: "filterBy", labelPosition: "none", id: "filter-by-plan", children: plans.map(function (p) { return (_jsx(WmOption, { "aria-label": p.title, selected: p.uuid === selectedPlan.uuid, onClick: function () {
                    dispatch(__assign(__assign({}, defaultPlanParticipationAction), { type: "setSelectedPlan", plan: {
                            uuid: p.uuid,
                            title: p.title,
                            assessmentTypeId: p.assessmentTypeId,
                            status: p.status,
                            updatedAt: ""
                        } }));
                }, children: p.title }, p.uuid)); }) }));
    };
    var getpssUrl = function (inst) {
        var url = "".concat(REDIRECT_URI, "/institution/").concat(inst.pssUuid, "/plan/immersive/").concat(planUuid, "/node/").concat(inst.rootOrgUuid, "/progress?tab=continuous-improvement-insights&reporting_year_uuid=").concat(selectedReportingYear.uuid, "&plan_uuid=").concat(planUuid);
        if (inst.navigatedFrom === "navigator") {
            url += "&institution_platform_uuid=".concat(inst.platformUuid);
        }
        return url;
    };
    var pssUrl = institution ? getpssUrl(institution) : "";
    var footer = function () {
        return (_jsx("div", { className: "footer", children: _jsx(WmButton, { "button-type": "textonly", onClick: function () { return institution && redirect(pssUrl); }, children: translate("app.planParticipation.viewMore", {
                    selectedPlanTitle: selectedPlanTitle.toString()
                }) }) }));
    };
    var showData = function () {
        return !state.loader && !state.partialLoader && !state.statsApiError;
    };
    var showStatsApiError = function () {
        return !state.loader && !state.partialLoader && state.statsApiError;
    };
    var showPlansApiError = function () {
        return !state.loader && !state.partialLoader && state.plansApiError;
    };
    var RenderAssessmentPlans = function () {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "body", children: [_jsx("div", { className: institution && institution.showCourseEvaluation
                                ? ""
                                : "plan-container", children: !state.loader && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "plan-dropdown-container flex-row", children: [_jsx("span", { children: translate("app.planParticipation.plan") }), _jsx("div", { className: "plan-dropdown", children: _jsx(PlanSelect, { plans: state.plans, selectedPlan: state.selectedPlan }) })] }), _jsxs("div", { className: "flex-row -justified plan-status-container", children: [_jsx("div", { className: "plan-title", children: state.selectedPlan.title }), _jsx(PlanStatus, { status: state.selectedPlan.status })] })] })) }), showData() && (_jsxs("div", { className: "flex-row insightsgrid", children: [planOrgBar(state.chartData, state.selectedPlan.assessmentTypeId, state.selectedPlan.status), planPrgmBar(state.chartData, state.selectedPlan.assessmentTypeId, state.selectedPlan.status), coursePrgmBar(state.chartData, state.selectedPlan.assessmentTypeId, state.selectedPlan.status)] }))] }), showData() && footer(), showStatsApiError() && _jsx(ApiError, {}), (state.loader || state.partialLoader) && (_jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" }))] }));
    };
    var notStarted = useIntl().formatMessage({
        id: "app.drilldown.notStarted"
    });
    var inProgress = useIntl().formatMessage({
        id: "app.drilldown.inProgress"
    });
    var inComplete = useIntl().formatMessage({
        id: "app.drilldown.inComplete"
    });
    var submitted = useIntl().formatMessage({
        id: "app.drilldown.submitted"
    });
    var participatingOrgs = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgs"
    });
    var participatingOrgsNotStarted = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsNotStarted"
    });
    var participatingDrilldown = useIntl().formatMessage({
        id: "app.planParticipation.participatingDrilldown"
    });
    var participatingOrgsInProgres = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsInProgres"
    });
    var participatingOrgsInComplete = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsInComplete"
    });
    var participatingOrgsSubmitted = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsSubmitted"
    });
    var participatingCoursesNotStarted = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesNotStarted"
    });
    var participatingCoursesInProgress = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesInProgress"
    });
    var participatingCoursesInComplete = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesInComplete"
    });
    var participatingCoursesSubmitted = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesSubmitted"
    });
    var coursePrgmBar = function (chartData, assessmentTypeId, status) {
        if (chartData.totalCourses > 0 && assessmentTypeId === 1) {
            return (_jsxs("div", { className: "flex-row bar-content", children: [_jsxs("div", { className: "counts dropnumber", children: [_jsx("p", { className: "count", children: chartData.totalParticipatingCourses.toString() +
                                    "/" +
                                    chartData.totalCourses.toString() }), _jsx("p", { className: "details", children: translate("app.planParticipation.courseParticipating") })] }), _jsx("div", { className: "chart", id: "participating-course-chart", children: _jsxs(WmChart, { chartType: "bar4", label: participatingCoursesByStatus, valueFormat: "amount", id: "prog-status-chart", children: [_jsx(WmChartSlice, { amount: chartData.notStartedCourseCount.toString(), legend: notStarted, "popover-title": chartData.notStartedCourseCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingCourses.toString(), "popover-text": participatingCoursesNotStarted }, "not_started_count"), _jsx(WmChartSlice, { amount: chartData.inProgressCourseCount.toString(), legend: status === 1 ? inComplete : inProgress, "popover-title": chartData.inProgressCourseCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingCourses.toString(), "popover-text": status === 1
                                        ? participatingCoursesInComplete
                                        : participatingCoursesInProgress }, "in_progress_count"), _jsx(WmChartSlice, { amount: chartData.submittedCourseCount.toString(), legend: submitted, "popover-title": chartData.submittedCourseCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingCourses.toString(), "popover-text": participatingCoursesSubmitted }, "Submitted")] }, "bar4-chart") })] }));
        }
    };
    var planOrgBar = function (chartData, assessmentTypeId, status) {
        if (chartData.totalParticipatingOrgs > 0 && assessmentTypeId === 0) {
            return (_jsxs("div", { className: "flex-row bar-content", children: [_jsxs("div", { className: "counts dropnumber", children: [_jsx("p", { className: "count", children: chartData.totalParticipatingOrgs.toString() +
                                    "/" +
                                    chartData.totalOrganizations.toString() }), _jsx("p", { className: "details", children: translate("app.planParticipation.orgParticipating") })] }), _jsx("div", { className: "chart", id: "participating-orgs-chart", children: _jsxs(WmChart, { chartType: "bar4", label: participatingOrgs, valueFormat: "amount", id: "org-status-chart", children: [_jsx(WmChartSlice, { amount: chartData.notStartedCount.toString(), legend: notStarted, "popover-title": chartData.notStartedCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingOrgs.toString(), "popover-text": participatingOrgsNotStarted, "popover-button-text": participatingDrilldown, onClick: function () {
                                        return openDrillDown(ASSESSMENT_PLANNING_ORGANIZATIONS, "any", "org-status-chart");
                                    } }, "not_started_count"), _jsx(WmChartSlice, { amount: chartData.inProgressCount.toString(), legend: status === 1 ? inComplete : inProgress, "popover-title": chartData.inProgressCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingOrgs.toString(), "popover-text": status === 1
                                        ? participatingOrgsInComplete
                                        : participatingOrgsInProgres, "popover-button-text": participatingDrilldown, onClick: function () {
                                        return openDrillDown(ASSESSMENT_PLANNING_ORGANIZATIONS, "any", "org-status-chart");
                                    } }, "in_progress_count"), _jsx(WmChartSlice, { amount: chartData.completedCount.toString(), legend: submitted, "popover-title": chartData.completedCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingOrgs.toString(), "popover-text": participatingOrgsSubmitted, "popover-button-text": participatingDrilldown, onClick: function () {
                                        return openDrillDown(ASSESSMENT_PLANNING_ORGANIZATIONS, "any", "org-status-chart");
                                    } }, "Submitted")] }, "bar4-chart") })] }));
        }
    };
    var participatingProgramsByStatus = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsByStatus"
    });
    var participatingCoursesByStatus = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesByStatus"
    });
    var participatingProgramsNotStarted = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsNotStarted"
    });
    var participatingDrilldownPrograms = useIntl().formatMessage({
        id: "app.planParticipation.participatingDrilldownPrograms"
    });
    var participatingProgramsInProgress = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsInProgress"
    });
    var participatingProgramsInComplete = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsInComplete"
    });
    var participatingProgramsCompleted = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsSubmitted"
    });
    var planPrgmBar = function (chartData, assessmentTypeId, status) {
        if (chartData.totalParticipatingPrgms > 0 && assessmentTypeId === 0) {
            return (_jsxs("div", { className: "flex-row bar-content", children: [_jsxs("div", { className: "counts dropnumber", children: [_jsx("p", { className: "count", children: chartData.totalParticipatingPrgms.toString() +
                                    "/" +
                                    chartData.totalPrograms.toString() }), _jsx("p", { className: "details", children: translate("app.planParticipation.programsParticipating") })] }), _jsx("div", { className: "chart", id: "participating-prgrm-chart", children: _jsxs(WmChart, { chartType: "bar4", label: participatingProgramsByStatus, valueFormat: "amount", id: "prog-status-chart", children: [_jsx(WmChartSlice, { amount: chartData.notStartedPrgmCount.toString(), legend: notStarted, "popover-title": chartData.notStartedPrgmCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingPrgms.toString(), "popover-text": participatingProgramsNotStarted, "popover-button-text": participatingDrilldownPrograms, onClick: function () {
                                        return openDrillDown(ASSESSMENT_PLANNING_PROGRAMS, "not_started", "prog-status-chart");
                                    } }, "not_started_count"), _jsx(WmChartSlice, { amount: chartData.inProgressPrgmCount.toString(), legend: status === 1 ? inComplete : inProgress, "popover-title": chartData.inProgressPrgmCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingPrgms.toString(), "popover-text": status === 1
                                        ? participatingProgramsInComplete
                                        : participatingProgramsInProgress, "popover-button-text": participatingDrilldownPrograms, onClick: function () {
                                        return openDrillDown(ASSESSMENT_PLANNING_PROGRAMS, "in_progress", "prog-status-chart");
                                    } }, "in_progress_count"), _jsx(WmChartSlice, { amount: chartData.completedPrgmCount.toString(), legend: submitted, "popover-title": chartData.completedPrgmCount.toString() +
                                        "/" +
                                        chartData.totalParticipatingPrgms.toString(), "popover-text": participatingProgramsCompleted, "popover-button-text": participatingDrilldownPrograms, onClick: function () {
                                        return openDrillDown(ASSESSMENT_PLANNING_PROGRAMS, "submitted", "prog-status-chart");
                                    } }, "Submitted")] }, "bar4-chart") })] }));
        }
    };
    var getURL = function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        if (locale === null) {
            return "/insights/reporting_year/".concat(selectedReportingYear.uuid, "/plan/").concat(state.selectedPlan.uuid, "/html");
        }
        else {
            return "/insights/reporting_year/".concat(selectedReportingYear.uuid, "/plan/").concat(state.selectedPlan.uuid, "/html?locale=").concat(locale);
        }
    };
    var openPlanHTMLViewLink = function () {
        return window.open(getURL(), "_blank");
    };
    var header = function () {
        return (_jsxs("div", { className: "header", children: [_jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.planParticipation.participationAssessment") }), _jsx("p", { className: "description", children: translate("app.planParticipation.reviewEngagement") })] }), _jsx("div", { children: showData() && state.plans.length > 0 && (_jsx(WmButton, { id: "export-button", "aria-controls": "acc-listbox", "aria-expanded": "false", tabIndex: 0, onClick: openPlanHTMLViewLink, "button-type": "secondary", children: translate("app.exportHtml") })) })] }));
    };
    var planParticipationBody = function () {
        return (_jsxs("div", { className: "paper-card paper-card-height", children: [header(), state.loader && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), !state.loader && (_jsxs(_Fragment, { children: [state.plans.length > 0 && _jsx(RenderAssessmentPlans, {}), state.plans.length === 0 && !showPlansApiError() && _jsx(NoPlans, {}), showPlansApiError() && _jsx(ApiError, {})] }))] }));
    };
    var DRILLDOWN_SUB_HEADER = useIntl().formatMessage({
        id: "app.planParticipation.subHeader"
    });
    return (_jsxs(_Fragment, { children: [planParticipationBody(), state.isDrilldownOpen ? (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "plan-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(OpenDrillDownView, { setIsDrilldownOpen: closeDrilldown, drilldownType: state.drilldownType, total: state.drilldownTotal, showTable: state.showTable, drilldownStatus: state.drilldownStatus, completeHierarchy: state.hierarchy, subHeader: DRILLDOWN_SUB_HEADER, filters: state.drilldownFilters, title: state.selectedPlan.title, leads: leads, planStatus: state.selectedPlan.status }), _jsx("div", { className: "sr-only", id: "plan-dialog-wrapper-flyout-bottom", tabIndex: 0 })] })) : ("")] }));
}
var PlanStatus = function (_a) {
    var status = _a.status;
    if (status === 1)
        return (_jsx("div", { className: "success-status -completed", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.completed") }) }));
    return (_jsx("div", { className: "in-progress-status-export", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.inProgress") }) }));
};
var NoPlans = function () {
    return (_jsxs("div", { className: "no-plans", children: [_jsx("div", { className: "no-plans-text", children: translate("app.continuousImprovement.planningAndParticipation.noPlansText") }), _jsx("div", { className: "no-plans-text-select", children: translate("app.continuousImprovement.planningAndParticipation.noPlansSubText") })] }));
};
