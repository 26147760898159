var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useReducer, useEffect } from "react";
import translate from "../../i18n/translate";
import { downloadPDF } from "../common/helper";
import { sendRequest, sendRequestWithPayload } from "../../common/http";
import html2canvas from "html2canvas";
import { useIntl } from "react-intl";
var defaultHeaderAction = {
    type: "",
    token: "",
    data: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setToken":
            return __assign(__assign({}, state), { token: action.token });
        case "setExportInProgress":
            return __assign(__assign({}, state), { isExportInProgress: action.data });
        default:
            return state;
    }
};
var initialState = {
    token: null,
    isExportInProgress: false
};
export default function Header(_a) {
    var title = _a.title;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var header = useIntl().formatMessage({
        id: "app.lightcast.regionLabel"
    });
    var exportToPdf = function (token, applyResult, onError) {
        var mainContent = document.getElementById("maincontent");
        focusOnDownloadMessage();
        if (mainContent) {
            var clone_1 = mainContent.cloneNode(true);
            var headerSection = clone_1.firstChild;
            headerSection && clone_1.removeChild(headerSection);
            var lfbElement = document.querySelector("#lfb-chart") ||
                document.querySelector("#api-error");
            var wrapperDiv_1 = document.createElement("div");
            var canvasNode_1 = null;
            var traverseNode_1 = function (node) {
                var nodeElement = node;
                if (nodeElement && nodeElement.id && nodeElement.id === "lfb-chart") {
                    canvasNode_1 = node;
                }
                if (node.hasChildNodes()) {
                    for (var _i = 0, _a = node.childNodes; _i < _a.length; _i++) {
                        var childNode = _a[_i];
                        traverseNode_1(childNode);
                        if (canvasNode_1)
                            break;
                    }
                }
                return node;
            };
            var el = clone_1.firstChild;
            while (el && el.hasChildNodes()) {
                traverseNode_1(el);
                el = el.nextSibling;
            }
            if (lfbElement) {
                html2canvas(lfbElement).then(function (canvas) {
                    var imgWrapper = document.createElement("div");
                    var img = document.createElement("img");
                    img.src = canvas.toDataURL("image/png");
                    imgWrapper.setAttribute("id", "lfb-chart-img");
                    imgWrapper.appendChild(img);
                    if (canvasNode_1 && canvasNode_1.parentNode) {
                        canvasNode_1.parentNode.replaceChild(imgWrapper, canvasNode_1);
                    }
                    wrapperDiv_1.appendChild(clone_1);
                    var formData = new FormData();
                    formData.append("html", wrapperDiv_1.outerHTML);
                    formData.append("_csrf_token", token);
                    formData.append("header", encodeURIComponent(header));
                    formData.append("sub_header", encodeURIComponent(title));
                    sendRequestWithPayload({
                        uri: "/export",
                        camelize: true,
                        body: formData
                    }, applyResult, onError);
                });
            }
        }
    };
    useEffect(function () {
        if (state.isExportInProgress) {
            sendRequest({
                uri: "/export/token",
                camelize: true
            }, function (result) {
                dispatch(__assign(__assign({}, defaultHeaderAction), { type: "setToken", token: result.token }));
            });
        }
    }, [state.isExportInProgress]);
    useEffect(function () {
        if (state.token && state.isExportInProgress) {
            var applyResult = function (result) {
                var fileTitle = "Labor_Market_Insights";
                downloadPDF(result.pdfFileBytes, fileTitle);
                dispatch(__assign(__assign({}, defaultHeaderAction), { type: "setToken", token: null }));
                dispatch(__assign(__assign({}, defaultHeaderAction), { type: "setExportInProgress", data: false }));
            };
            var onError = function (error) {
                // Log error in console for debugging
                console.log("API Error:", error);
                dispatch(__assign(__assign({}, defaultHeaderAction), { type: "setToken", token: null }));
                dispatch(__assign(__assign({}, defaultHeaderAction), { type: "setExportInProgress", data: false }));
            };
            exportToPdf(state.token, applyResult, onError);
        }
    }, [state.isExportInProgress, state.token]);
    return (_jsx(_Fragment, { children: _jsx("section", { className: "page-banner-container -mainheader -withbreadcrumbs only-breadcrumbs header-padding", id: "page-header", children: _jsxs("div", { className: "flex-row export-container", children: [_jsxs("div", { children: [_jsx("div", { children: _jsx("span", { className: "org-name uppercase", children: title }) }), _jsx("div", { className: "identity-details", children: _jsx("div", { className: "page-header -titleonly", children: _jsx("h2", { className: "plan-title-header", children: header }) }) })] }), _jsxs("div", { className: "flex-row export-button", children: [state.isExportInProgress && (_jsx("span", { className: "download-text", children: translate("app.lightcast.exportPdf.downloadReportText") })), _jsx("div", { className: "button-container", children: _jsx(WmButton, { "button-type": "secondary", icon: "f1da", "icon-size": "20px", onClick: function () {
                                        return dispatch(__assign(__assign({}, defaultHeaderAction), { type: "setExportInProgress", data: true }));
                                    }, disabled: state.isExportInProgress, children: translate("app.lightcast.exportPdf.download") }) })] })] }) }) }));
}
function focusOnDownloadMessage() {
    var downloadText = document.querySelector(".download-text");
    if (downloadText) {
        downloadText.setAttribute("tabindex", "0");
        downloadText.focus();
    }
}
