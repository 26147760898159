var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer } from "react";
import "../../app/App.scss";
import { IntlProvider } from "react-intl";
import ENGLISH from "../../i18n/languages/en-US.json";
import { pig } from "../../static/js/pig.js";
import Header from "../laborMarketHtml/Header";
import { sendRequest } from "../../common/http";
import InstitutionRegionContext from "../laborMarketHtml/InstitutionRegionContext";
import TrendsExport from "../laborMarketHtml/TrendsExport";
import Loader from "../common/elements/Loader";
import translate from "src/i18n/translate";
import { defaultLaborForceBreakdown, defaultRegionalContext, defaultCoordinates } from "./Interface";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
var defaultLoadedComponents = {
    institution: false,
    institutionRegionContext: false,
    trends: false
};
var defaultInstitution = {
    name: ""
};
var defaultRegionalContextResultset = {
    regionalContext: defaultRegionalContext,
    showLightcastWidget: false
};
var defaultLaborMarketExportViewAction = {
    type: "",
    institution: defaultInstitution,
    locale: "",
    lfb: defaultLaborForceBreakdown,
    loadedComponents: defaultLoadedComponents,
    regionalContextResultset: defaultRegionalContextResultset,
    component: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setInstitution":
            return setInstitution(state, action.institution);
        case "setLocale":
            return setLocale(state, action.locale);
        case "setRegionalContext":
            return __assign(__assign({}, state), { regionalContext: action.regionalContextResultset.regionalContext, auth: true });
        case "disableAuth":
            return __assign(__assign({}, state), { auth: false });
        case "setLaborForceBreakdown":
            return __assign(__assign({}, state), { laborForceBreakdown: action.lfb });
        case "setLoadedComponent":
            var component = action.component;
            var loadedComponents = state.loadedComponents;
            loadedComponents[component] = true;
            return __assign(__assign({}, state), { loadedTabs: loadedComponents });
        case "setInstitutionApiError":
            return __assign(__assign({}, state), { institutionApiError: true });
        case "setRegionalContextApiError":
            return __assign(__assign({}, state), { regionalContextApiError: true });
        default:
            return state;
    }
};
var setLocale = function (state, locale) {
    switch (locale) {
        case "pig": {
            return __assign(__assign({}, state), { locale: "en-US", messages: pig(ENGLISH) });
        }
        default: {
            return __assign(__assign({}, state), { locale: "en-US", messages: ENGLISH });
        }
    }
};
var setInstitution = function (state, institution) {
    return __assign(__assign({}, state), { institution: institution, regionalContext: null, auth: true });
};
var allComponentsLoaded = function (loadedComponents) {
    return (loadedComponents.institution &&
        loadedComponents.institutionRegionContext &&
        loadedComponents.trends);
};
export default function LaborMarketExportView(_a) {
    var auth = _a.auth;
    var onComponentLoad = function (component) {
        dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "setLoadedComponent", component: component }));
    };
    useEffect(function () {
        var applyResult = function (result) {
            if ("showLightcastWidget" in result) {
                !result.showLightcastWidget &&
                    dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "disableAuth" }));
            }
            else {
                dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "setInstitution", institution: result }));
            }
            onComponentLoad("institution");
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "setInstitutionApiError" }));
            onComponentLoad("institution");
        };
        auth &&
            sendRequest({
                uri: "/lightcast/institution",
                camelize: true
            }, applyResult, onError);
    }, [auth]);
    useEffect(function () {
        var applyResult = function (result) {
            if ("showLightcastWidget" in result && !result.showLightcastWidget) {
                dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "disableAuth" }));
            }
            else {
                dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "setRegionalContext", regionalContextResultset: result }));
            }
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "setRegionalContextApiError" }));
        };
        auth &&
            sendRequest({ uri: "/lightcast/regional_context", camelize: true }, applyResult, onError);
    }, [auth]);
    var initialState = {
        locale: "en-US",
        messages: null,
        institution: {},
        loadedComponents: {
            institution: false,
            institutionRegionContext: false,
            trends: false
        },
        regionalContext: {
            coordinates: defaultCoordinates,
            countyCodes: [],
            mapId: "",
            mapTilerApiKey: "",
            noOfCounties: 0,
            radius: 0,
            timeFrame: "",
            zip: 0
        },
        laborForceBreakdown: null,
        auth: true,
        regionalContextApiError: false,
        institutionApiError: false
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "setLocale", locale: locale }));
    }, []);
    var setLaborForceBreakdown = function (lfb) {
        dispatch(__assign(__assign({}, defaultLaborMarketExportViewAction), { type: "setLaborForceBreakdown", lfb: lfb }));
    };
    return (_jsx(_Fragment, { children: auth && state.auth ? (_jsx("div", { className: "pagewrapper participation-container", children: _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { className: "nav-main", children: _jsx("div", { className: "contentwrapper", children: _jsx("main", { id: "maincontent", className: "main-container", children: state.messages && (_jsxs(IntlProvider, { locale: state.locale, messages: state.messages, children: [!allComponentsLoaded(state.loadedComponents) && (_jsx(Loader, {})), _jsxs(_Fragment, { children: [_jsx(Header, { title: state.institution.name }), _jsx("div", { className: "wm-grid lightcast-body-html", children: _jsxs("div", { className: "institution-region-context", children: [state.auth ? (_jsx(InstitutionRegionContext, { regionalContext: state.regionalContext, onComponentLoad: onComponentLoad })) : (""), _jsx("div", { className: "lightcast-source", children: translate("app.lightcast.Source") }), _jsx("div", { className: "widget-border" }), _jsx("div", { className: "grid-container wm-grid-col-12 wm-grid-col-lg-12 wm-grid-col-md-10 wm-grid-col-sm-8 trends-container", children: state.auth ? (_jsx(TrendsExport, { setLaborForceBreakdown: setLaborForceBreakdown, laborForceBreakdown: state.laborForceBreakdown, onComponentLoad: onComponentLoad })) : ("") })] }) })] })] })) }) }) }) }) })) : (_jsx(UnauthorizedComponent, {})) }));
}
