var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReducer, useEffect } from "react";
import "../Lightcast.scss";
import { sendRequest } from "../../../common/http";
import BottomGrowingOccupations from "./BottomGrowingOccupations";
import TopGrowingOccupations from "./TopGrowingOccupations";
import translate from "src/i18n/translate";
var defaultIndustriesOrOccupations = {
    top: [],
    bottom: []
};
var defaultOccupationAction = {
    type: "",
    data: defaultIndustriesOrOccupations
};
var initialState = {
    bottomGrowingOccupations: null,
    topGrowingOccupations: null,
    loader: true,
    apiError: false
};
var reducer = function (state, action) {
    var _a, _b;
    switch (action.type) {
        case "setOccupations":
            return __assign(__assign({}, state), { bottomGrowingOccupations: (_a = action.data) === null || _a === void 0 ? void 0 : _a.bottom, topGrowingOccupations: (_b = action.data) === null || _b === void 0 ? void 0 : _b.top, loader: false });
        case "setOccupationsError":
            return __assign(__assign({}, state), { bottomGrowingOccupations: null, topGrowingOccupations: null, loader: false, apiError: true });
        default:
            return state;
    }
};
export default function Occupations(_a) {
    var onComponentLoad = _a.onComponentLoad;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var applyResult = function (result) {
            dispatch({ type: "setOccupations", data: result });
            onComponentLoad("occupations");
        };
        var onError = function (error) {
            dispatch(__assign(__assign({}, defaultOccupationAction), { type: "setOccupationsError" }));
        };
        sendRequest({ uri: "/lightcast/occupations", camelize: true }, applyResult, onError);
    }, []);
    return (_jsxs("div", { className: "grid-container wm-grid-col-12 wm-grid-col-lg-12 wm-grid-col-md-10 wm-grid-col-sm-8", children: [_jsx("div", { className: "industries-occupations-label -occupation", children: translate("app.lightcast.occupationsLabel") }), _jsxs("div", { className: "wm-grid-row", children: [_jsx("div", { className: "grid-container wm-grid-col-xl-6 wm-grid-col-lg-6 wm-grid-col-md-10 wm-grid-col-sm-8 -min-width", children: _jsx(BottomGrowingOccupations, { occupations: state.bottomGrowingOccupations, loader: state.loader }) }), _jsx("div", { className: "grid-container wm-grid-col-xl-6 wm-grid-col-lg-6 wm-grid-col-md-10 wm-grid-col-sm-8 -min-width", children: _jsx(TopGrowingOccupations, { occupations: state.topGrowingOccupations, loader: state.loader }) })] })] }));
}
