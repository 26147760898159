import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from "react-intl";
export function InstitutionIcon() {
    return (_jsx("svg", { className: "institution-icon", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M19.2084 9.77908H22L11.8663 4L2 9.77908H4.52868V19.8786H3.13093V20.5517H20.6068V19.8786H19.2084V9.77908ZM4.49525 9.10595L11.8708 4.78553L19.4471 9.10595H4.49525ZM5.22756 19.8786V9.77908H7.32485V19.8786H5.22756ZM8.02373 19.8786V9.77908H10.121V19.8786H8.02373ZM10.8199 19.8786V9.77908H12.9172V19.8786H10.8199ZM13.6161 19.8786V9.77908H15.7134V19.8786H13.6161ZM16.4122 19.8786V9.77908H18.5095V19.8786H16.4122Z", fill: "#575195" }) }));
}
export function FolderIcon(_a) {
    var color = _a.color;
    var imageIcon = useIntl().formatMessage({
        id: "app.home.taskIcon"
    });
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-label": imageIcon, children: _jsx("path", { d: "M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V6H9.17L11.17 8H20V18ZM15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13ZM11 17H19V16C19 14.67 16.33 14 15 14C13.67 14 11 14.67 11 16V17Z", fill: color }) }));
}
export function SettingsIcon() {
    var imageIcon = useIntl().formatMessage({
        id: "app.home.taskIcon"
    });
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-label": imageIcon, children: _jsx("path", { d: "M9.58991 13C10.1399 13 10.5899 13.45 10.5899 14C10.5899 14.55 10.1399 15 9.58991 15C9.03991 15 8.58991 14.55 8.58991 14C8.58991 13.45 9.03991 13 9.58991 13ZM9.58991 11C7.92991 11 6.58991 12.34 6.58991 14C6.58991 15.66 7.92991 17 9.58991 17C11.2499 17 12.5899 15.66 12.5899 14C12.5899 12.34 11.2499 11 9.58991 11ZM18.0899 9L19.1799 6.59L21.5899 5.5L19.1799 4.41L18.0899 2L16.9999 4.41L14.5899 5.5L16.9999 6.59L18.0899 9ZM20.8699 12.72L20.0899 11L19.3099 12.72L17.5899 13.5L19.3099 14.28L20.0899 16L20.8699 14.28L22.5899 13.5L20.8699 12.72ZM15.8399 14C15.8399 13.88 15.8399 13.75 15.8299 13.63L17.7699 12.16L15.2699 7.83L13.0299 8.77C12.8299 8.64 12.6099 8.51 12.3899 8.4L12.0899 6H7.08991L6.78991 8.41C6.56991 8.52 6.35991 8.65 6.14991 8.78L3.90991 7.83L1.40991 12.16L3.34991 13.63C3.33991 13.75 3.33991 13.88 3.33991 14C3.33991 14.12 3.33991 14.25 3.34991 14.37L1.40991 15.84L3.90991 20.17L6.14991 19.23C6.34991 19.36 6.56991 19.49 6.78991 19.6L7.08991 22H12.0899L12.3899 19.59C12.6099 19.48 12.8199 19.36 13.0299 19.22L15.2699 20.16L17.7699 15.83L15.8299 14.36C15.8399 14.25 15.8399 14.12 15.8399 14ZM14.4199 17.64L12.6899 16.91C12.1299 17.51 11.3899 17.95 10.5599 18.14L10.3199 20H8.85991L8.62991 18.14C7.79991 17.95 7.05991 17.51 6.49991 16.91L4.76991 17.64L4.03991 16.37L5.52991 15.24C5.40991 14.85 5.34991 14.44 5.34991 14.01C5.34991 13.58 5.40991 13.17 5.52991 12.78L4.03991 11.65L4.76991 10.38L6.49991 11.11C7.05991 10.51 7.79991 10.07 8.62991 9.88L8.85991 8H10.3299L10.5599 9.86C11.3899 10.05 12.1299 10.49 12.6899 11.09L14.4199 10.36L15.1499 11.63L13.6599 12.76C13.7799 13.15 13.8399 13.56 13.8399 13.99C13.8399 14.42 13.7799 14.83 13.6599 15.22L15.1499 16.35L14.4199 17.64Z", fill: "#575195" }) }));
}
export function CheckCircle() {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z", fill: "#4A4A4A" }) }));
}
export function CheckCircleFilled() {
    return (_jsx("svg", { width: "15", height: "15", viewBox: "0 0 15 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M7.48828 0C9.62109 0.046875 11.3906 0.773438 12.7969 2.17969C14.2031 3.58594 14.9297 5.35547 14.9766 7.48828C14.9297 9.62109 14.2031 11.3906 12.7969 12.7969C11.3906 14.2031 9.62109 14.9297 7.48828 14.9766C5.35547 14.9297 3.58594 14.2031 2.17969 12.7969C0.773438 11.3906 0.046875 9.62109 0 7.48828C0.046875 5.35547 0.773438 3.58594 2.17969 2.17969C3.58594 0.773438 5.35547 0.046875 7.48828 0ZM6.75 10.8633L11.9883 5.625L10.9336 4.57031L6.75 8.75391L4.42969 6.43359L3.375 7.48828L6.75 10.8633Z", fill: "#088000" }) }));
}
export function ProgramIcon() {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: "program-icon", children: _jsx("path", { d: "M12.1751 4L12.319 4.07194L21.4856 8.65523L22 8.91243L21.5162 9.22343L17.7377 11.6521L17.738 20.4063H6.2798L6.27949 11.5683L3.98784 10.1455L3.98815 17.1325H3.3334L3.33308 9.73959L2.50599 9.2262L2 8.91214L2.53631 8.65323L12.0303 4.06993L12.1751 4ZM6.93424 11.9743L6.93456 19.7515H17.0833L17.083 12.0725L12.1757 15.2281L12 15.119L6.93424 11.9743ZM12.17 4.72945L3.35697 8.98406L12.1687 14.4532L20.6779 8.9834L12.17 4.72945Z", fill: "#575195" }) }));
}
export function UserGroupIcon() {
    return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M22 9V7H20V9H18V11H20V13H22V11H24V9H22Z", fill: "#575195" }), _jsx("path", { d: "M8 12C10.21 12 12 10.21 12 8C12 5.79 10.21 4 8 4C5.79 4 4 5.79 4 8C4 10.21 5.79 12 8 12ZM8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6Z", fill: "#575195" }), _jsx("path", { d: "M8 13C5.33 13 0 14.34 0 17V20H16V17C16 14.34 10.67 13 8 13ZM14 18H2V17.01C2.2 16.29 5.3 15 8 15C10.7 15 13.8 16.29 14 17V18Z", fill: "#575195" }), _jsx("path", { d: "M12.51 4.05C13.43 5.11 14 6.49 14 8C14 9.51 13.43 10.89 12.51 11.95C14.47 11.7 16 10.04 16 8C16 5.96 14.47 4.3 12.51 4.05Z", fill: "#575195" }), _jsx("path", { d: "M16.53 13.83C17.42 14.66 18 15.7 18 17V20H20V17C20 15.55 18.41 14.49 16.53 13.83Z", fill: "#575195" })] }));
}
export function TickIcon() {
    return (_jsx("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M5.86339 10.5834L3.08339 7.80336L2.13672 8.74336L5.86339 12.47L13.8634 4.47003L12.9234 3.53003L5.86339 10.5834Z", fill: "#088000" }) }));
}
export function ClockIcon() {
    return (_jsx("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M7.99967 1.33337C4.33301 1.33337 1.33301 4.33337 1.33301 8.00004C1.33301 11.6667 4.33301 14.6667 7.99967 14.6667C11.6663 14.6667 14.6663 11.6667 14.6663 8.00004C14.6663 4.33337 11.6663 1.33337 7.99967 1.33337ZM7.99967 13.3334C5.05967 13.3334 2.66634 10.94 2.66634 8.00004C2.66634 5.06004 5.05967 2.66671 7.99967 2.66671C10.9397 2.66671 13.333 5.06004 13.333 8.00004C13.333 10.94 10.9397 13.3334 7.99967 13.3334ZM8.33301 4.66671H7.33301V8.66671L10.7997 10.8L11.333 9.93337L8.33301 8.13337V4.66671Z", fill: "#366DAE" }) }));
}
export function ExpandIcon() {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z", fill: "#366DAE" }) }));
}
export function ExpandLess() {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M12 8.29492L6 14.2949L7.41 15.7049L12 11.1249L16.59 15.7049L18 14.2949L12 8.29492Z", fill: "#366DAE" }) }));
}
export function TooltipArrow() {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M7 9.5L12 14.5L17 9.5H7Z", fill: "#366DAE" }) }));
}
export function SentBackIcon() {
    return (_jsx("svg", { width: "14", height: "12", viewBox: "0 0 14 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M13.3203 0V11.6406H10.8203V0H13.3203ZM9.17969 0V11.6406L0 5.82031L9.17969 0Z", fill: "#8C4B00" }) }));
}
export function OnHoldIcon() {
    return (_jsx("svg", { width: "9", height: "10", viewBox: "0 0 9 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M0 9.5H2.71429V0H0V9.5ZM5.42857 0V9.5H8.14286V0H5.42857Z", fill: "#4A4A4A" }) }));
}
export function SkippedIcon() {
    return (_jsx("svg", { width: "10", height: "10", viewBox: "0 0 10 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M7.9043 9.5V0H9.5V9.5H7.9043ZM0 9.5V0L6.7168 4.75L0 9.5Z", fill: "#4A4A4A" }) }));
}
export function SkippedToIcon() {
    return (_jsx("svg", { width: "13", height: "12", viewBox: "0 0 13 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M0 0L5.56641 5.5293L0 11.0586V0ZM11.0957 0H12.6914V11.0586H11.0957V0ZM5.56641 0L11.0957 5.5293L5.56641 11.0586V0Z", fill: "#4A4A4A" }) }));
}
export function JointAppointmentsIcon() {
    return (_jsx("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M8.35938 0H13.3594V5L11.4453 3.08594L9.02344 5.50781L7.85156 4.33594L10.2734 1.91406L8.35938 0ZM5 0L3.08594 1.91406L7.5 6.32812V13.3594H5.85938V7.03125L1.91406 3.08594L0 5V0H5Z", fill: "#4A4A4A" }) }));
}
export function NewTabIcon() {
    return (_jsx("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z", fill: "#575195" }) }));
}
