var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer } from "react";
import "../../../app/App.scss";
import { sendRequest } from "../../../common/http";
import { useParams } from "react-router-dom";
import { IntlProvider } from "react-intl";
import ENGLISH from "../../../i18n/languages/en-US.json";
import { pig } from "../../../static/js/pig.js";
import Header from "../../continuous-improvement/export/Header";
import ParticipatingOrganizations from "../../continuous-improvement/export/ParticipatingOrganizations";
import { defaultPlanResultSet, defaultPlan } from "../../common/interfaces/Interface";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
var defaultAction = {
    type: "",
    locale: "",
    plan_uuid: "",
    result: defaultPlanResultSet,
    showDownloadBtn: true
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setPlans":
            return setPlans(state, action);
        case "setLocale":
            return setLocale(state, action.locale);
        case "setShowDownloadBtn":
            return __assign(__assign({}, state), { showDownloadBtn: action.showDownloadBtn });
        default:
            return state;
    }
};
var setPlans = function (state, action) {
    var selectedPlans = action.result.plans.filter(function (plan) {
        return plan.uuid === action.plan_uuid;
    });
    var selectedPlan = selectedPlans.length > 0 ? selectedPlans[0] : defaultPlan;
    return __assign(__assign({}, state), { plans: action.result.plans, selectedPlan: selectedPlan, showLoader: false });
};
var setLocale = function (state, locale) {
    switch (locale) {
        case "pig": {
            return __assign(__assign({}, state), { locale: "en-US", messages: pig(ENGLISH) });
        }
        default: {
            return __assign(__assign({}, state), { locale: "en-US", messages: ENGLISH });
        }
    }
};
var getLocaleString = function (locale) {
    return locale ? locale : "";
};
export default function PlanView(_a) {
    var auth = _a.auth;
    var _b = useParams(), reportingYearUuid = _b.reporting_year_uuid, planUuid = _b.plan_uuid;
    var initialState = {
        locale: "en-US",
        messages: ENGLISH,
        plans: [],
        selectedPlan: defaultPlan,
        showLoader: true,
        showDownloadBtn: true
    };
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = getLocaleString(param.get("locale"));
        dispatch(__assign(__assign({}, defaultAction), { type: "setLocale", locale: locale }));
    }, []);
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setPlans", result: result, plan_uuid: planUuid }));
        };
        auth &&
            sendRequest({
                uri: "/plans?reporting_year_uuid=".concat(reportingYearUuid),
                camelize: true
            }, applyResult);
    }, [auth]);
    return (_jsx(_Fragment, { children: auth ? (_jsx("div", { className: "pagewrapper participation-container", children: _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { className: "nav-main", children: _jsx("div", { className: "contentwrapper", children: _jsx("main", { id: "maincontent", className: "main-container", children: state.messages && (_jsxs(IntlProvider, { locale: state.locale, messages: state.messages, children: [_jsx(Header, { showLoader: state.showLoader, title: state.selectedPlan.title, planType: state.selectedPlan.assessmentTypeId, showDownloadBtn: state.showDownloadBtn }), _jsx(ParticipatingOrganizations, { plan: state.selectedPlan, showDownloadBtnChange: function (showDownloadBtn) {
                                            return dispatch(__assign(__assign({}, defaultAction), { type: "setShowDownloadBtn", showDownloadBtn: showDownloadBtn }));
                                        } })] })) }) }) }) }) })) : (_jsx(UnauthorizedComponent, {})) }));
}
