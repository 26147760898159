var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import "../../../app/App.scss";
import { useIntl } from "react-intl";
import { sendRequest } from "../../../common/http";
import { IntlProvider } from "react-intl";
import ENGLISH from "../../../i18n/languages/en-US.json";
import { useParams } from "react-router-dom";
import { pig } from "../../../static/js/pig.js";
import Header from "./Header";
import Loader from "../../common/elements/Loader";
import translate from "src/i18n/translate";
import { dateWithoutTimezone } from "../../common/elements/ReportingYears";
import { formatDate } from "../../common/helper";
import ApiError from "../../common/elements/ApiError";
import { AccomplishmentsChart } from "../AccomplishmentsChart";
import FacultySuccessCollegesTable from "./FacultySuccessCollegesTable";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
import { defaultReportingYear } from "../../common/interfaces/Interface";
var facultySuccessAction = {
    type: "",
    reportingYear: defaultReportingYear,
    scholarshipSummary: [],
    locale: "en-US",
    accomplishmentsByCategory: null,
    summaryError: false,
    accomplishmentsError: false,
    reportingYearError: false
};
var setScholarshipSummary = function (state, scholarshipSummary) {
    var total = scholarshipSummary.find(function (acc) { return acc.category === "Total"; });
    var accomplishments = scholarshipSummary
        .filter(function (acc) { return acc.category !== "Total"; })
        .sort(function (acc1, acc2) {
        return acc1.order > acc2.order ? 1 : -1;
    });
    return __assign(__assign({}, state), { accomplishments: accomplishments, totalAccomplishments: total && total.count });
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setReportingYear":
            if (action.reportingYear) {
                return __assign(__assign({}, state), { reportingYear: action.reportingYear });
            }
            else {
                return __assign(__assign({}, state), { reportingYear: null, reportingYearAuth: false });
            }
        case "setLocale":
            return setLocale(state, action.locale);
        case "setScholarshipSummary":
            return setScholarshipSummary(state, action.scholarshipSummary);
        case "setAccomplishmentsByCategory":
            return __assign(__assign({}, state), { accomplishmentsByCategory: action.accomplishmentsByCategory });
        case "setSummaryError":
            return __assign(__assign({}, state), { summaryError: true });
        case "setAccomplishmentsError":
            return __assign(__assign({}, state), { accomplishmentsError: true });
        case "setReportingYearError":
            return __assign(__assign({}, state), { reportingYearError: true });
        default:
            return state;
    }
};
var setLocale = function (state, locale) {
    switch (locale) {
        case "pig": {
            return __assign(__assign({}, state), { locale: "en-US", messages: pig(ENGLISH) });
        }
        default: {
            return __assign(__assign({}, state), { locale: "en-US", messages: ENGLISH });
        }
    }
};
export default function FacultySuccessExportView(_a) {
    var auth = _a.auth;
    var initialState = {
        locale: "en-US",
        messages: ENGLISH,
        reportingYear: null,
        reportingYearAuth: true,
        accomplishments: [],
        totalAccomplishments: null,
        accomplishmentsByCategory: null,
        summaryError: false,
        accomplishmentsError: false,
        reportingYearError: false
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var _c = useParams(), reportingYearUuid = _c.reporting_year_uuid, mappingId = _c.mapping_id;
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        dispatch(__assign(__assign({}, facultySuccessAction), { type: "setLocale", locale: locale }));
    }, []);
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, facultySuccessAction), { type: "setReportingYear", reportingYear: result }));
        };
        var onError = function () {
            dispatch(__assign(__assign({}, facultySuccessAction), { type: "setReportingYearError", reportingYearError: true }));
        };
        auth &&
            sendRequest({
                uri: "/sa/reporting_years/".concat(reportingYearUuid),
                camelize: true
            }, applyResult, onError);
    }, [auth]);
    useEffect(function () {
        var _a, _b;
        if (mappingId && state.reportingYear) {
            var applyResult = function (_a) {
                var scholarshipSummary = _a.scholarshipSummary;
                dispatch(__assign(__assign({}, facultySuccessAction), { type: "setScholarshipSummary", scholarshipSummary: scholarshipSummary }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, facultySuccessAction), { type: "setSummaryError", summaryError: true }));
            };
            auth &&
                sendRequest({
                    uri: "/faculty_success/scholarship_summary" +
                        "?mapping_id=" +
                        mappingId +
                        "&start_date=" +
                        dateWithoutTimezone((_a = state.reportingYear) === null || _a === void 0 ? void 0 : _a.startDate) +
                        "&end_date=" +
                        dateWithoutTimezone((_b = state.reportingYear) === null || _b === void 0 ? void 0 : _b.endDate),
                    camelize: true
                }, applyResult, onError);
        }
    }, [auth, mappingId, state.reportingYear]);
    useEffect(function () {
        var _a, _b;
        if (mappingId && state.reportingYear) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, facultySuccessAction), { type: "setAccomplishmentsByCategory", accomplishmentsByCategory: result }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, facultySuccessAction), { type: "setAccomplishmentsError", accomplishmentsError: true }));
            };
            auth &&
                sendRequest({
                    uri: "/faculty_success/accomplishments/" +
                        "?mapping_id=" +
                        mappingId +
                        "&start_date=" +
                        dateWithoutTimezone((_a = state.reportingYear) === null || _a === void 0 ? void 0 : _a.startDate) +
                        "&end_date=" +
                        dateWithoutTimezone((_b = state.reportingYear) === null || _b === void 0 ? void 0 : _b.endDate)
                }, applyResult, onError);
        }
    }, [auth, mappingId, state.reportingYear]);
    var Accomplishments = function () {
        var _a, _b;
        return (_jsxs("div", { className: "", children: [_jsx("div", { className: "heading-label", children: translate("app.faculty.htmlChartLabel", {
                        category: "Institution"
                    }) }), !state.summaryError && (_jsxs("div", { className: "flex-row accomplishments-container", children: [_jsxs("div", { className: "", children: [_jsx("div", { className: "acomplishments-heading-html", children: translate("app.faculty.totalAccomplishments") }), _jsx("div", { className: "accomplishments-count-html", children: state.totalAccomplishments }), state.reportingYear && state.reportingYear.startDate && (_jsx("div", { className: "accomplishments-years", children: translate("app.faculty.accomplishmentsYear", {
                                        startDate: "".concat(formatDate((_a = state.reportingYear) === null || _a === void 0 ? void 0 : _a.startDate)),
                                        endDate: "".concat(formatDate((_b = state.reportingYear) === null || _b === void 0 ? void 0 : _b.endDate))
                                    }) }))] }), _jsx("div", { className: "chart-fs-html", id: "scholarship-summary-export", children: _jsx(AccomplishmentsChart, { accomplishments: state.accomplishments, forHtml: true }) })] })), state.summaryError && _jsx(ApiError, {})] }));
    };
    return (_jsx("div", { className: "pagewrapper faculty-html-container", children: _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { className: "nav-main", children: _jsxs("div", { className: "contentwrapper", children: [!state.reportingYearError && (_jsx("main", { id: "maincontent", className: "main-container", children: state.messages && (_jsxs(IntlProvider, { locale: state.locale, messages: state.messages, children: [auth &&
                                        state.reportingYearAuth &&
                                        ((!state.summaryError && !state.totalAccomplishments) ||
                                            (!state.accomplishmentsError &&
                                                state.accomplishmentsByCategory === null)) ? (_jsx(Loader, {})) : (""), _jsxs("div", { id: "facultyHtml", className: "faculty-html-container", children: [state.reportingYear && (_jsx(Header, { reportingYear: state.reportingYear.name, showDownloadBtn: !state.accomplishmentsError || !state.summaryError })), auth && state.reportingYearAuth ? (_jsxs("section", { className: "-mainheader -withbreadcrumbs only-breadcrumbs body-padding", id: "page-header", children: [_jsx(Accomplishments, {}), state.accomplishmentsByCategory &&
                                                        Object.keys(state.accomplishmentsByCategory.accomplishments).map(function (accomplishmentName) {
                                                            return (state.accomplishmentsByCategory && (_jsx(AccomplishmentsByCategory, { accomplishmentByCategory: state.accomplishmentsByCategory
                                                                    .accomplishments[accomplishmentName], category: accomplishmentName }, accomplishmentName)));
                                                        }), state.accomplishmentsError && (_jsx("div", { className: "html-border", children: _jsx(ApiError, {}) }))] })) : (_jsx(UnauthorizedComponent, {}))] })] })) })), state.reportingYearError && _jsx(ApiError, {})] }) }) }) }));
}
var AccomplishmentsByCategory = function (_a) {
    var accomplishmentByCategory = _a.accomplishmentByCategory, category = _a.category;
    var heading = useIntl().formatMessage({ id: "app.faculty.htmlChartLabel" }, { category: category });
    var categoryColumnHeader = useIntl().formatMessage({ id: "app.faculty.category" }, { category: category });
    return (_jsxs("div", { className: "html-border", children: [_jsx("div", { className: "category-heading-label", children: heading }), accomplishmentByCategory && (_jsx(CategoryStats, { category: category, accomplishmentByCategory: accomplishmentByCategory })), _jsx("div", { className: "table-border", children: accomplishmentByCategory && (_jsx(FacultySuccessCollegesTable, { accomplishmentByCategory: accomplishmentByCategory, categoryColumnHeader: categoryColumnHeader })) })] }));
};
var CategoryStats = function (_a) {
    var accomplishmentByCategory = _a.accomplishmentByCategory, category = _a.category;
    var total = Object.keys(accomplishmentByCategory).length;
    var count = Object.keys(accomplishmentByCategory).reduce(function (acc, key) {
        var total = accomplishmentByCategory[key]["Total"];
        return (acc = acc + (total > 0 ? 1 : 0));
    }, 0);
    return (_jsx("div", { className: "category-stats-count", children: translate("app.faculty.categoryStatsLabel", {
            count: count,
            total: total,
            category: category
        }) }));
};
